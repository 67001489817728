var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"form"},[_c('h3',[_vm._v("Olá")]),_c('p',[_vm._v("Para ativar seu cadastro é necessário confirmar seus dados.")]),_c('div',[_c('validation-observer',{ref:"formValidation"},[_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit.apply(null, arguments)}}},[_c('b-form-group',{attrs:{"id":"input-group-1","label":"Nome Completo","label-for":"name"}},[_c('validation-provider',{attrs:{"name":"Nome","vid":"name","rules":"required|min:4"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"name","name":"name","placeholder":"Informe seu nome completo","state":errors.length > 0 ? false : null},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('b-form-group',{attrs:{"id":"input-group-2","label":"CPF","label-for":"cpf"}},[_c('validation-provider',{attrs:{"name":"CPF","vid":"cpf","rules":"required|cpf"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{directives:[{name:"mask",rawName:"v-mask",value:('###.###.###-##'),expression:"'###.###.###-##'"}],attrs:{"id":"cpf","name":"cpf","placeholder":"Informe seu CPF","state":errors.length > 0 ? false : null},model:{value:(_vm.form.cpf),callback:function ($$v) {_vm.$set(_vm.form, "cpf", $$v)},expression:"form.cpf"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('b-form-group',{attrs:{"id":"input-group-3","label":"CRM","label-for":"crm"}},[_c('validation-provider',{attrs:{"name":"CRM","vid":"crm","rules":"required|min:2"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"crm","name":"crm","placeholder":"Informe seu CRM","state":errors.length > 0 ? false : null},model:{value:(_vm.form.crm),callback:function ($$v) {_vm.$set(_vm.form, "crm", $$v)},expression:"form.crm"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-form-group',{attrs:{"id":"input-group-5","label":"Senha","label-for":"password"}},[_c('validation-provider',{attrs:{"name":"Senha","vid":"password","rules":"required|password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',[_c('b-form-input',{attrs:{"id":"password","name":"password","type":_vm.showPassword ? 'text' : 'password',"placeholder":"Defina sua senha","state":errors.length > 0 ? false : null},on:{"input":function($event){return _vm.changePassword(_vm.form.password)}},model:{value:(_vm.form.password),callback:function ($$v) {_vm.$set(_vm.form, "password", $$v)},expression:"form.password"}}),_c('b-input-group-append',[_c('b-button',{staticClass:"password-btn",attrs:{"variant":"default"},on:{"click":function($event){_vm.showPassword = !_vm.showPassword}}},[(!_vm.showPassword)?_c('b-icon',{attrs:{"icon":"eye-fill"}}):_c('b-icon',{attrs:{"icon":"eye-slash-fill"}})],1)],1)],1),_c('password',{attrs:{"strength-meter-only":true},model:{value:(_vm.form.password),callback:function ($$v) {_vm.$set(_vm.form, "password", $$v)},expression:"form.password"}}),_c('ul',[_c('li',{class:{'has-error': _vm.passwordErrors.length === false, 'is-valid': _vm.passwordErrors.length === true}},[_vm._v("Ao menos 8 caracteres")]),_c('li',{class:{'has-error': _vm.passwordErrors.lowercase === false, 'is-valid': _vm.passwordErrors.lowercase === true}},[_vm._v("Ao menos 1 letra minúscula (a-z)")]),_c('li',{class:{'has-error': _vm.passwordErrors.uppercase === false, 'is-valid': _vm.passwordErrors.uppercase === true}},[_vm._v("Ao menos 1 letra maiúscula (A-Z)")]),_c('li',{class:{'has-error': _vm.passwordErrors.numbers === false, 'is-valid': _vm.passwordErrors.numbers === true}},[_vm._v("Ao menos um número (0-9)")]),_c('li',{class:{'has-error': _vm.passwordErrors.special === false, 'is-valid': _vm.passwordErrors.special === true}},[_vm._v("Ao menos um caractere especial ("+_vm._s(_vm.specialChars)+")")])])]}}])})],1),_c('b-form-group',{attrs:{"id":"input-group-4","label":"Estado","label-for":"state"}},[_c('validation-provider',{attrs:{"name":"Estado","vid":"state","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"id":"state","name":"state","options":_vm.states,"state":errors.length > 0 ? false : null},model:{value:(_vm.form.state_id),callback:function ($$v) {_vm.$set(_vm.form, "state_id", $$v)},expression:"form.state_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-checkbox',{attrs:{"id":"checkbox-1","name":"checkbox-1","value":"1","unchecked-value":"0"},model:{value:(_vm.accepted),callback:function ($$v) {_vm.accepted=$$v},expression:"accepted"}},[_vm._v(" Aceito "),_c('router-link',{staticClass:"term-link",attrs:{"target":"_blank","to":"/termo"}},[_vm._v("termos de uso")])],1),_c('b-form-group',{attrs:{"id":"input-group-6"}},[_vm._v(" Acesse a nossa política de privacidade clicando "),_c('a',{staticClass:"term-link",attrs:{"target":"_blank","href":"https://imediato.online/privacy-policy"}},[_vm._v("aqui")]),_vm._v(". ")]),_c('div',{staticClass:"btn-submit-container"},[_c('b-button',{attrs:{"type":"submit","variant":"primary"}},[_vm._v("Confimar")])],1)],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }