import Vue from 'vue'
import App from './App.vue'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import VueMask from 'v-mask'
import VueRouter from 'vue-router'
import { VueReCaptcha } from 'vue-recaptcha-v3'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import './app.scss'
import router from './router'

Vue.config.productionTip = false

Vue.use(VueRouter)
Vue.use(VueMask);
Vue.use(IconsPlugin)

Vue.use(BootstrapVue)
Vue.use(VueReCaptcha, {
  siteKey: process.env.VUE_APP_RECAPTCHA_SITE_KEY,
  loaderOptions: {
    useRecaptchaNet: true,
  },
})

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
