<template>
  <div class="term">
    <div v-html="termHtml"></div>
  </div>
</template>

<script>
import axios from 'axios'

const axiosInstance = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  withCredentials: true
})

export default {
  data() {
    return {
      termHtml: '',
    }
  },
  created() {
    axiosInstance.get('/term/current')
      .then(response => {
        this.termHtml = response.data.data.template
      })
      .catch(error => {
        console.log(error);
      });
  },
}
</script>

<style scoped lang="sass">
.term
  margin: 30px 10px 10px
  background-color: #ffffff
  padding: 18px
  color: #626262
  text-align: left
</style>
