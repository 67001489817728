import Vue from 'vue'
import VueRouter from 'vue-router'
import ConfirmationForm from '../views/ConfirmationForm.vue'
import FormExpired from '../views/FormExpired.vue'
import FormSubmitted from '../views/FormSubmitted.vue'
import CurrentTerm from '../views/CurrentTerm';

Vue.use(VueRouter)

const routes = [
  {
    path: '/jwt/:token',
    name: 'Token',
    component: ConfirmationForm,
    props: true
  },
  {
    path: '/',
    name: 'ConfirmationForm',
    component: ConfirmationForm,
    props: true
  },
  {
    path: '/termo',
    name: 'CurrentTerm',
    component: CurrentTerm,
  },  
  {
    path: '/enviado',
    name: 'FormSubmitted',
    component: FormSubmitted
  },
  {
    path: '/expirado',
    name: 'FormExpired',
    component: FormExpired
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
