<template>
  <div class="form">
    <h3>Olá</h3>
    <p>Para ativar seu cadastro é necessário confirmar seus dados.</p>
    
    <div>
      <validation-observer ref="formValidation">
        <b-form @submit.prevent="onSubmit">
          <b-form-group id="input-group-1" label="Nome Completo" label-for="name">
            <validation-provider
              #default="{ errors }"
              name="Nome"
              vid="name"
              rules="required|min:4"
            >
              <b-form-input
                  id="name"
                  name="name"
                  v-model="form.name"
                  placeholder="Informe seu nome completo"
                  :state="errors.length > 0 ? false : null"
              ></b-form-input>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-row>
            <b-col
                cols="12"
                md="6"
            >
              <b-form-group class="" id="input-group-2" label="CPF" label-for="cpf">
                <validation-provider
                    #default="{ errors }"
                    name="CPF"
                    vid="cpf"
                    rules="required|cpf"
                >
                  <b-form-input
                      id="cpf"
                      name="cpf"
                      v-model="form.cpf"
                      v-mask="'###.###.###-##'"
                      placeholder="Informe seu CPF"
                      :state="errors.length > 0 ? false : null"
                  ></b-form-input>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
                cols="12"
                md="6"
            >
              <b-form-group id="input-group-3" label="CRM" label-for="crm">
                <validation-provider
                    #default="{ errors }"
                    name="CRM"
                    vid="crm"
                    rules="required|min:2"
                >
                  <b-form-input
                      id="crm"
                      name="crm"
                      v-model="form.crm"
                      placeholder="Informe seu CRM"
                      :state="errors.length > 0 ? false : null"
                  ></b-form-input>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          
          <b-form-group id="input-group-5" label="Senha" label-for="password">
            <validation-provider
                #default="{ errors }"
                name="Senha"
                vid="password"
                rules="required|password"
            >
              <b-input-group>
                <b-form-input
                    id="password"
                    name="password"
                    :type="showPassword ? 'text' : 'password'"
                    v-model="form.password"
                    placeholder="Defina sua senha"
                    :state="errors.length > 0 ? false : null"
                    @input="changePassword(form.password)"
                >
                </b-form-input>
                <b-input-group-append>
                  <b-button variant="default" class="password-btn" @click="showPassword = !showPassword">    
                    <b-icon v-if="!showPassword" icon="eye-fill"></b-icon>
                    <b-icon v-else icon="eye-slash-fill"></b-icon>
                  </b-button>
                </b-input-group-append>
              </b-input-group>
              <password v-model="form.password" :strength-meter-only="true" />
              <ul>
                <li :class="{'has-error': passwordErrors.length === false, 'is-valid': passwordErrors.length === true}">Ao menos 8 caracteres</li>
                <li :class="{'has-error': passwordErrors.lowercase === false, 'is-valid': passwordErrors.lowercase === true}">Ao menos 1 letra minúscula (a-z)</li>
                <li :class="{'has-error': passwordErrors.uppercase === false, 'is-valid': passwordErrors.uppercase === true}">Ao menos 1 letra maiúscula (A-Z)</li>
                <li :class="{'has-error': passwordErrors.numbers === false, 'is-valid': passwordErrors.numbers === true}">Ao menos um número (0-9)</li>
                <li :class="{'has-error': passwordErrors.special === false, 'is-valid': passwordErrors.special === true}">Ao menos um caractere especial ({{ specialChars }})</li>
              </ul>
            </validation-provider>
          </b-form-group>
  
          <b-form-group id="input-group-4" label="Estado" label-for="state">
            <validation-provider
                #default="{ errors }"
                name="Estado"
                vid="state"
                rules="required"
            >
              <b-form-select
                  id="state"
                  name="state"
                  v-model="form.state_id"
                  :options="states"
                  :state="errors.length > 0 ? false : null"
              ></b-form-select>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>


          
          <b-form-checkbox
              id="checkbox-1"
              v-model="accepted"
              name="checkbox-1"
              value="1"
              unchecked-value="0"
          >
            Aceito <router-link target="_blank" class="term-link" to="/termo">termos de uso</router-link>
          </b-form-checkbox>

          <b-form-group id="input-group-6">
            Acesse a nossa política de privacidade clicando <a target="_blank" class="term-link" href="https://imediato.online/privacy-policy">aqui</a>.
          </b-form-group>

          <div class="btn-submit-container">
            <b-button type="submit" variant="primary">Confimar</b-button>
          </div>
        </b-form>
      </validation-observer>
    </div>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver, extend, localize} from 'vee-validate'
import ptBR from 'vee-validate/dist/locale/pt_BR.json'
import {
  required,
  email,
  min,
} from 'vee-validate/dist/rules'
import Password from 'vue-password-strength-meter'
import axios from 'axios'
import Swal from 'sweetalert2'
import jwtDecode from "jwt-decode";
import cpfValidator from '../validations/cpf';
import { validatorPassword } from '../validations/password';

const axiosInstance = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  withCredentials: true
})

export default {
  components: { Password, ValidationProvider, ValidationObserver },
  props: {
    token: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      required: extend('required', required),
      email: extend('email', email),
      min: extend('min', min),
      cpf: extend('cpf', {
        validate: cpfValidator,
        message: 'CPF inválido',
      }),
      password: extend('password', {
        validate: validatorPassword,
        message: '{_field_} deve conter pelo menos uma letra maiúscula, uma minúscula, um caractere especial e um dígito e ter no mínimo 8 caracteres',
      }),
      accepted: false,
      invalidPassword: false,
      showPassword: false,
      specialChars: '!?@#$%&*()',
      passwordErrors: {
        length: null,
        lowercase: null,
        uppercase: null,
        special: null,
        numbers: null,
      },
      form: {
        cpf: '',
        name: '',
        crm: '',
        password: '',
        template_id: '',
        state_id: null,
      },
      states: [
          { text: 'Informe seu estado', value: null },
      ],
    }
  },
  created() {
    localize('pt_BR', {
      messages: ptBR.messages,
      names: {
        email: 'Email',
        name: 'Nome',
        password: 'Password',
      },
      fields: {
        password: {
          min: 'O {_field_} é muito pequeno. Escolha uma senha mais forte.',
        },
      },
    })
    this.loadStates()
    this.loadTemplate()
    if (this.token) this.saveToken(this.token);
    
    if (this.token && this.$route.name === 'Token') {
      this.$router.push({ name: 'ConfirmationForm' })
    } else if (!this.getToken()) {
      this.$router.push({ name: 'FormExpired' })
    }
  },
  mounted() {
    this.form.name = this.getTokenUserName()
  },
  methods: {
    changePassword(password) {
      this.passwordErrors.length = false
      this.passwordErrors.lowercase = false
      this.passwordErrors.uppercase = false
      this.passwordErrors.special = false
      this.passwordErrors.numbers = false

      if (password.length >= 8) this.passwordErrors.length = true
      if (/[a-z]/.test(password)) this.passwordErrors.lowercase = true
      if (/[A-Z]/.test(password)) this.passwordErrors.uppercase = true
      if (/\d/.test(password)) this.passwordErrors.numbers = true
      if (/[!@#$%&*()]/.test(password)) this.passwordErrors.special = true
    },
    showScore (score) {
      this.invalidPassword = score < 3
    },
    saveToken(token) {
      localStorage.setItem('globalmed:token', token);
    },
    getToken() {
      return localStorage.getItem('globalmed:token');
    },
    removeToken() {
      localStorage.removeItem('globalmed:token');
    },
    getTokenUserId() {
      const token = this.getToken();
      const decoded = jwtDecode(token);
      return decoded.jti;
    },
    getTokenUserName() {
      try {
        const token = this.getToken();
        const decoded = jwtDecode(token);
        return decoded.name;
      } catch {
        return ''
      }
    },
    loadStates() {
      axiosInstance.get('/address/states')
        .then(response => {
          const states = response.data.data.map(state => ({ text: `${state.name} (${state.abbreviation})`, value: state.id }))
          this.states = this.states.concat(states)
        })
        .catch(error => {
          console.log(error);
        });
    },
    loadTemplate() {
      axiosInstance.get('/term/current')
        .then(response => {
          this.form.template_id = response.data.data.template_id
        })
        .catch(error => {
          console.log(error);
        });
    },
    async onSubmit() {
      const success = await this.$refs.formValidation.validate()
      if (!success) return

      if (this.accepted != '1') {
        Swal.fire({
          title: 'Termo de uso',
          text: 'Necessário aceitar termos de uso',
          icon: 'info',
          confirmButtonText: 'Continuar'
        })
        return;
      }
      
      try {
        await this.$recaptchaLoaded()

        const token = this.getToken();
        const userId = this.getTokenUserId();
        const headers = { 'X-Api-Key': token }
        const formData = {
          ...this.form,
          cpf: this.form.cpf.replace(/\D/g, ''),
          recaptcha: await this.$recaptcha('form') 
        };
        axiosInstance
          .put(`/physician/${userId}/from-invite`, formData,{ headers })
          .then(() => {
            this.removeToken();
            this.$router.push({ name: 'FormSubmitted' })
          })
          .catch(error => {
            const errors = (error.response && error.response.status === 422 && error.response.data.errors) || {}
            if (Object.keys(errors).length) {
              const messages = Object.keys(errors).map((key) => {
                return errors[key].join('<br/>')
              }).join('<br/>')
              Swal.fire({
                title: 'Inválido',
                html: messages,
                icon: 'error',
                confirmButtonText: 'Continuar'
              })
            } else if (error.response.data && error.response.data.message) {
              Swal.fire({
                title: 'Inválido',
                text: error.response.data.message,
                icon: 'error',
                confirmButtonText: 'Continuar'
              })
            } else {
              this.$router.push({ name: 'FormExpired' })
            }
          });
      } catch (err) {
        console.log(err)
        this.removeToken();
        this.$router.push({ name: 'FormExpired' })
      }
    },
  }
}
</script>

<style scoped lang="sass">
$color: #ea5d23
$colorDanger: #dc3545
$colorSuccess: #28a745

ul 
  padding-left: 16px 
  margin-bottom: 0
  li
    font-size: 12px
  li.has-error
    color: $colorDanger
  li.is-valid
    color: $colorSuccess

.password-btn
  border: 1px solid #ced4da !important
  outline: none
  background-color: transparent !important
  color: $color

.term-link
  text-decoration: underline
  &:hover
    color: #ea5d23!important

.form
  background-color: #ffffff
  padding: 18px
  color: #626262
  text-align: left   
  h3
    margin: 10px 0 20px 0
  .btn-submit-container
    text-align: right
    margin-top: 10px
    button
      font-weight: 600
      font-size: 14px
      line-height: 100%
      padding: 16px 24px
      border-radius: 0.358rem
      border: 1px solid $color !important
      background-color: $color !important
      color: #ffffff
  .Password
    width: 100%!important
    max-width: 100%!important
</style>

<style lang="sass">
  .swal2-html-container
    text-align: left
  .form
    label
      margin-bottom: 0
      
  .Password__strength-meter
    margin: 5px auto 5px!important
</style>
